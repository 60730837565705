import React, { useEffect, useState } from "react";
import { ComposePageContentType } from "../config";
import { getComposeAdvertisingPageQueryCollection } from "../graphQL/queries/getComposePageQueryCollections";
import { useQuery } from "@apollo/client";
import { BlockLoader } from "../components/loader";
import { HeroBannerComponent } from "../components/heroBannerComponent";
import { FreeFormContent } from "../components/FreeFormContent";
import { ComponentCard } from "../components/ComponentCard";
import { PopUpCard } from "../components/PopupCard";
import { ComponentCardCollection } from "../components/ComponentCardCollection";
import { SiteMap } from "../components/siteMap";
import { useDocumentTitle } from "../hooks/useDocumentTitle";
import { useLocation } from "react-router-dom";
import { NewsletterForm } from "../components/Newsletterform";
import { Helmet } from "react-helmet";
import { SubMenu } from "../components/subMenu";

export const ComposePage = () => {
  const { COMPONENT_CARD, POPUP_CARDS, COMPONENT_CARD_COLLECTION } =
    ComposePageContentType;
  const [title, setTitle] = useState("");
  const [composePageContent, setComposePageContent] = useState([]);
  const slug = useLocation().pathname;
  const { data, loading } = useQuery(getComposeAdvertisingPageQueryCollection, {
    variables: {
      usePreview: false,
      slug: slug,
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setTitle(data?.ComposeAdvertisingPage[0]?.pageTitle);
      setComposePageContent(
        data?.ComposeAdvertisingPage?.[0]?.content?.content
      );
    },
    onError: (error) => console.log(error),
  });
  useDocumentTitle(title);
  return (
    <>
      {data?.ComposeAdvertisingPage?.[0]?.seo && (
        <Helmet>
          <meta
            property="og:title"
            content={data.ComposeAdvertisingPage?.[0]?.seo?.seoTitle}
          />
          <meta
            property="og:description"
            content={data.ComposeAdvertisingPage?.[0]?.seo?.internalName}
          />

          <meta
            name="description"
            content={data.ComposeAdvertisingPage?.[0]?.seo?.internalName}
          />
        </Helmet>
      )}
      {data?.ComposeAdvertisingPage[0]?.subMenu?.menuSections?.[0] && (
        <SubMenu content={data?.ComposeAdvertisingPage[0]?.subMenu} />
      )}
      {loading ? (
        <BlockLoader />
      ) : (
        <div className="compose-Page-screens">
          {composePageContent?.map((content: any, index: number) => (
            <React.Fragment key={`composePageContent-${index}`}>
              <div>
                {content?.bannerContent?.bannerImage?.file && (
                  <HeroBannerComponent banner={content} />
                )}

                {content?.freeFormContent && (
                  <FreeFormContent
                    content={content?.freeFormContent}
                  ></FreeFormContent>
                )}
                {content?.menu && <SiteMap content={content?.menu}></SiteMap>}
                {content?.componentCards?.map((cards: any, index: number) => (
                  <>
                    {cards?.contentType === COMPONENT_CARD && (
                      <ComponentCard
                        componentCardSectionTitle={
                          content?.componentCardSectionTitle
                        }
                        componentCards={cards}
                        componentCardDisplayTile={
                          content?.componentCardDisplayTile
                        }
                      />
                    )}
                    {cards?.contentType === POPUP_CARDS && (
                      <PopUpCard
                        popupCards={cards?.popupcards}
                        isSlider={cards?.isSlider}
                        componentCardSectionTitle={
                          content?.componentCardSectionTitle
                        }
                        componentCardDisplayTile={
                          content?.componentCardDisplayTile
                        }
                      />
                    )}

                    {cards?.contentType === COMPONENT_CARD_COLLECTION && (
                      <ComponentCardCollection
                        componentCardSectionTitle={
                          content?.componentCardSectionTitle
                        }
                        componentCards={
                          cards?.componentCardCollection?.componentCards
                        }
                        componentCardDisplayTile={
                          content?.componentCardDisplayTile
                        }
                      />
                    )}
                  </>
                ))}
                {content?.resourceSet && (
                  <NewsletterForm value={content?.resourceSet}></NewsletterForm>
                )}
              </div>
            </React.Fragment>
          ))}
        </div>
      )}
    </>
  );
};
