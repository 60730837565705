import { gql } from "@apollo/client";

export const resourceFields = gql`
  fragment resourceFields on AdvResourceSet {
    formSubTitle
    formTitle
    internalName
    resources {
      key
      required
      value
    }
  }
`;
