import { gql } from '@apollo/client';
 
export const getSocialMediaIconsQuery = gql`
  query getSocialMedia{
    SocialMedia(usePreview: false tags: "socialMedia") {
        sectionName
        sectionLinks{
          internalName
          publicName
          itemUrl
          itemIcon
        }
      }
  }`