import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { createBrowserHistory , History } from 'history';
 
/**

* Simple functional component that leverages react-router-dom to scroll top on Route Change

* NOTE: This cannot be added to shared

* -- useLocation() doesnt seem to work when imported from '@sky-tv-group/shared' and only works when in the same package

* @param param0

*/

const ScrollToTop = () => {

  const { pathname } = useLocation();
 
  const history = createBrowserHistory();

 
  useEffect(() => {

    window.scrollTo(0, 0);
 
    history.listen((location: any) => {

      if ('noScroll' in (location.state ?? {})) return;

      setTimeout(() => {

        if (location.hash) {

          let ele = window.document.querySelector(location.hash);

          if (ele) {

            ele.scrollIntoView({ behavior: 'smooth' });

          }

        }

      }, 1);

    });

  }, [pathname]);

  return null;

};
 
export { ScrollToTop };

 