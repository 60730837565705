import React, { useState, useEffect, useRef } from 'react';

export const SubMenu = ({ content }: any) => {
  const [activeSection, setActiveSection] = useState<number | null>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  // Toggle the active section
  const handleSectionClick = (index: number) => {
    setActiveSection(activeSection === index ? null : index);
  };

  // Handle clicks outside of the menu
  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setActiveSection(null);
    }
  };

  useEffect(() => {
    // Attach event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);
    
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="menu text-blue-pure flex pt-6 pb-8" ref={menuRef}>
      <h1>{content.pageTitle}</h1>
      {content.menuSections.map((section: any, index: number) => (
        <div key={index} className="menu-section">
          {section.sectionName && (
            <div
              className="section-header"
              onClick={() => handleSectionClick(index)}
            >
              <a href={section.slug}>{section.sectionName}</a><span className="dropdown-arrow"></span>
            </div>
          )}
          {!section.sectionName && (
            <div className='section-header'>
              <a
                key={index}
                href={section?.itemUrl}
              >
                {section?.publicName}
              </a>
            </div>
          )}
          {activeSection === index && section.sectionLinks && (
            <ul className="section-links list-none">
              {section.sectionLinks.map((link: any, linkIndex: number) => (
                <li key={linkIndex}>
                  <a
                    href={link.itemUrl}
                    className="section-link"
                  >
                    {link?.publicName}
                  </a>
                </li>
              ))}
            </ul>
          )}
        </div>
      ))}
    </div>
  );
};
