import { gql } from "@apollo/client";

export const MenuFields = gql`
  fragment MenuFields on AdMenuList {
     menuSections{
          publicName
          itemUrl
          sectionName
          slug
          sectionLinks{
            publicName
            itemUrl
            internalName
          }
        }
  }
`;
