import React from "react";
import { Pagination } from "./pagination";
import Slider from "react-slick";

interface T_Popup {
  popupCardImageURL: string;
  popupCardTitle: string;
  popupCardSubtitle: string;
  popupCardDescription: any;
  popupCardIconURL: string;
  linkText?: string;
  linkUrl?: string;
  internalName?: string;
}

interface IWhatsHotProps {
  popupCards: T_Popup[];
  componentCardSectionTitle: string;
  componentCardDisplayTile: string;
  backgroundColorGradient?:string;
  isCarousel?: boolean;
  isSlider?: boolean;
}
interface IWhatsHotCardProps {
  backgroundColorGradient: string;
  popUpCard?: T_Popup;
}

export const PopUpCard = ({
  popupCards,
  isSlider,
  backgroundColorGradient,
  componentCardSectionTitle,
  componentCardDisplayTile,
}: IWhatsHotProps) => {
  console.log(JSON.stringify(popupCards)+"popupCards");
  let displayTile: any;
  let objectFitTile: any, widthTile: any, heightTile: any;
  switch (componentCardDisplayTile?.replace(" ", "")) {
    case "Twotile":
      displayTile = "grid-cols-2 gap-4";
      widthTile = "40rem";
      heightTile = "28rem";
      objectFitTile = "cover";
      break;

    case "Threetile":
      displayTile = "grid-cols-3 gap-4";
      widthTile = "40rem";
      heightTile = "auto";
      objectFitTile = "cover";
      break;

    default:
      displayTile = "grid-cols-1 gap-0";
      widthTile = "40rem";
      heightTile = "auto";
      objectFitTile = "cover";
      break;
  }

  function assignBackgroundGradient(index: number) {
    let backgroundColorGradient: string;
    switch (index) {
      case 1:
        backgroundColorGradient = "sky-blue-gradient";
        break;
      case 2:
        backgroundColorGradient = "sky-peach-gradient";
        break;
      case 3:
        backgroundColorGradient = "sky-blue-gradient";
        break;
      case 4:
        backgroundColorGradient = "sky-young-passion-gradient";
        break;
      case 5:
        backgroundColorGradient = "sky-tempting-azure-gradient";
        break;
      default:
        backgroundColorGradient = "sky-blue-gradient";
        break;
    }
    return backgroundColorGradient;
  }
  const WhatsHotCard = ({
    backgroundColorGradient,
    popUpCard,
  }: IWhatsHotCardProps) => {
    return (
      <>
        <div
          style={{
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            width: widthTile,
            height: heightTile,
            objectFit: objectFitTile,
          }}
          className={`w-full  flex-none relative lg:${displayTile} 
                              xl:w-4/12 h-auto
                              md:w-6/12 md:flex-grow md:overflow-hidden overflow-hidden group`}
        >
          {componentCardDisplayTile === "Two tile" && (
            <>
              {" "}
              <a href={popUpCard?.linkUrl}>
                {" "}
                <div className="object-cover w-full h-full">
                  <img
                    className="w-full h-full block"
                    src={popUpCard?.popupCardImageURL}
                    alt=""
                  />
                </div>
                <div className="absolute bottom-0 sky-text-white overflow-auto m-4 z-10">
                  <h3 className="text-[1.1rem] font-semibold">
                    {popUpCard?.linkText}
                  </h3>
                </div>
                {isSlider && (
                  <Silder
                    popUpCard={popUpCard}
                    backgroundColorGradient={backgroundColorGradient}
                  />
                )}
              </a>
            </>
          )}
          {componentCardDisplayTile === "Three tile" && (
            <>
              {" "}
              <div className="object-cover">
                <img
                  className="w-1/2 h-1/2 block"
                  src={popUpCard?.popupCardImageURL}
                  alt=""
                />
              </div>
              <div className="w-1/2 ">
                <h3 className="text-[1.1rem] font-semibold sky-h3-black text-wrap">
                  {popUpCard?.popupCardTitle}
                </h3>
                <h4 className="text-[1rem] font-semibold sky-h4-black text-wrap">
                  {popUpCard?.popupCardSubtitle}
                </h4>
                <p className="text-center">{popUpCard?.popupCardDescription}</p>
                <button className="sky-button sky-button--dark sky-bg-midnight resources">
                  <a href={popUpCard?.linkUrl}>{popUpCard?.linkText}</a>
                </button>
              </div>
            </>
          )}
        </div>
      </>
    );
  };

  const Silder = ({
    popUpCard,
    backgroundColorGradient,
  }: IWhatsHotCardProps) => {
    return (
      <>
        <div
          className={`absolute top-0 left-0 h-full w-full 
                      overflow-hidden ${backgroundColorGradient}
                      transform -translate-x-full group-hover:translate-x-0
                      transition duration-500 flex flex-col items-start`}
        >
          <div className="sky-text-white flex flex-col pt-4 px-4 overflow-auto h-85% mb-14">
            <h4 className="sky-h4-reg sm:sky-h6 md:sky-h6">
              {popUpCard?.popupCardSubtitle}
            </h4>
            <div className="mt-7 overflow-auto">
              <p className="sky-h5 sky-text-white text-left">
                {
                  popUpCard?.popupCardDescription?.content?.[0]?.content?.[0]
                    ?.value
                }
              </p>
            </div>
          </div>
          <div className="absolute bottom-0 right-0 mr-3 mb-3">
            <img
              className="h-auto w-16"
              src={popUpCard?.popupCardIconURL}
              alt=""
            />
          </div>
        </div>
      </>
    );
  };
console.log(popupCards+"popups");
  return (
    <>
      {componentCardSectionTitle && (
        <div className="px-4 py-14 sky-blue-gradient text-center text-white mb-6">
          <h1
            className="sky-h4 sm:sky-h4 md:sky-h4 mt-3 text-center text-4xl p-2"
            style={{ fontFamily: "MarkPro-Book SKY" }}
            dangerouslySetInnerHTML={{
              __html: componentCardSectionTitle as string,
            }}
          ></h1>
        </div>
      )}
 
 {popupCards.length<12 && 
      <div className={`pt-0 ${componentCardDisplayTile === "Three tile"
            ? "item-list grid grid-cols-1 sm:grid-cols-3 w-full gap-2 mx-auto px-10 sm:px-40"
            : "flex flex-col pb-8  flex-no-wrap my-0 sm:flex-wrap sm:flex-row sm:overflow-hidden"
      }`} >
      
       <> {popupCards?.map((item, i) => (
          <WhatsHotCard
            key={i}
            popUpCard={item}
            backgroundColorGradient={assignBackgroundGradient(i)}
          />
        ))}
        </>
        </div>
}
        {popupCards.length>12 &&
        <div className="flex flex-col pb-8  flex-no-wrap my-0">
        <Pagination cards={popupCards}/>
        
        </div>
}
      
    </>
  );
};
