import React from 'react';// Adjust the path as needed
import { useAuth0 } from '@auth0/auth0-react';

const LogoutButton = () => {
  const { logout,isAuthenticated } = useAuth0();

  return (
    isAuthenticated ?
            <React.Fragment><button className="inline-block text-white text-xl bold mr-4" onClick={() =>
                logout({ logoutParams: { returnTo: window.location.origin } })}>
      Log Out
    </button>
    </React.Fragment>:<></>
  );
};

export default LogoutButton;
