import React, { useState } from 'react';
import { Link } from '../../types';
import { Facebook, Insta, Twitter, Youtube } from '../Header/SocialIcons';
import { useQuery } from '@apollo/client';
import { getSocialMediaIconsQuery } from '../../graphQL/queries/getSocialMediaIconsQuery';
interface Props {
  socialIcons: Link[];
}
export const SocialButtons = () => {
  const [socialMedia, setSocialMedia]= useState([]);
  const { data, error, loading } = useQuery(getSocialMediaIconsQuery, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      const results = data?.SocialMedia?.[0]?.sectionLinks;
      setSocialMedia(results);
    },
    onError: (error) => console.log(error),
  });
  return (
    <div className='social-media'>
     <ul className="flex flex-1 flex-row flex-no-wrap pl-6 p-8 text-white list-none">
                    {socialMedia?.map((media: any) => {
                      return (
                        <li
                          key={media.publicName}
                          className="text-lg px-4 bolds"
                        >
                          <a href={media.itemUrl}><img src={media.itemIcon} alt={media.publicName} /></a>
                        </li>
                        
                      );
                    })}
                  </ul>
    </div>
  );
};
