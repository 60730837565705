import { gql } from '@apollo/client';
export const freeFormContent = gql`
  fragment freeFormContent on AdvertisingFreeFormContents {
      heading
      headingTextPosition
      headingBackgroundGradientColor
      backgroundImage{
        file
        url
      }
      videoUrl
      logoImage{
        title
        file
        url
      }

      internalName
      subHeading
      content
  }
`;
