
import { gql } from '@apollo/client';
 
export const getFooterItemCollectionQuery = gql`
query GetFooterMenuList {
    MenuListHeader(usePreview: false,tags:"advertisingFooter" ) {
      internalName
      footerCopyRight
     menuItems{
       itemUrl
       itemIcon
       publicName
       internalName
     }
     menuSections{
      sectionName
       itemUrl
       itemIcon
       publicName
       internalName
     }
     
     }
   }`