import React, { useEffect, useState } from 'react';
import './App.css';
import { ComposePage } from './screens/composePage';
import { ErrorContainer } from './components/error-page/ErrorContainer';
import { pageErrorMessage } from './config';
import { Header } from './components/Header/Header';
import { Footer } from './components/Footer/Footer';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { getGlobalMenuListQuery } from './graphQL/queries/getGlobalMenuListQuery';
import { NotFoundContainer } from './components/not-found-page/NotFoundContainer';
import { useAuth0 } from "@auth0/auth0-react";
import { BlockLoader } from './components/loader';
let Body = () => {

  const [menuList, setMenuList] = useState<any>();
  const [isRedirecting, setIsRedirecting] = useState(false);
  const { isAuthenticated, loginWithRedirect} = useAuth0();
  const { data } = useQuery(
    getGlobalMenuListQuery,
    {fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      const results = data?.MenuListHeader[0]?.menuSections;
      setMenuList(results);
  },
  onError: (error) => console.log(error), }
  );
  
  useEffect(() => {
    const redirectIfNeeded = async () => {
      if (window.location.pathname === "/competitive-spend" && !isAuthenticated) {
        setIsRedirecting(true);
        await loginWithRedirect(); // Assuming this is a promise
      }
    };
    
    redirectIfNeeded();
  }, [isAuthenticated]);

  if (isRedirecting) {
    return null; // or a loading spinner while redirecting
  }

  return (

    <div>
        <Routes >
          {menuList && menuList?.map((item: any,i:any) => {
            return(
              <Route
                path={`${item.itemUrl}`}
                element={<ComposePage />}
              />
            )
          })}
          <Route path="/home" element={<Navigate replace to="/" />} />
        {data && menuList &&  <Route path="*" element={<NotFoundContainer redirectURL='/' label='Go Home' />} />
}</Routes>
        
    </div>
  )
}



function App() {
  const [errorPresent, setErrorPresent] = useState(false);
  const { isLoading, error } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <BlockLoader />;
  }

  // only true if we catch an error on load - for konakart
  if (errorPresent)
    return (
      <>
        <ErrorContainer
          headerText={pageErrorMessage.header}
          lineOne={pageErrorMessage.lineOne}
          lineTwo={pageErrorMessage.lineTwo}
        />
      </>
    );


  return (
    <div className="App">
      <Header/>
      <Body/>
      <Footer/> 
    </div>
  );
}

export default App;
