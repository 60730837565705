
import { setContext } from '@apollo/client/link/context';
import { ApolloClient, ApolloProvider, InMemoryCache, createHttpLink } from '@apollo/client';

import { GRAPHQL_URL } from './../config';
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  // const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers
     // authorization:  `Bearer mhL2wS-8kFGdUMfOSCVmw7ElOqZECwdYBMkzkmk_8fI` ,
    }
  }
});
const httpLink = createHttpLink({
   //uri: 'http://localhost:4001/graphql',
  uri:GRAPHQL_URL
 //uri:'https://web-graphql.dev.sky.co.nz/dev/graphql'
});


export const gqlAgentMyAccount = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

