import React, { ReactNode } from 'react';
import { Document, BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { Options, documentToReactComponents } from '@contentful/rich-text-react-renderer';

const renderOptions: Options = {
  renderText: (text: string) =>
    text
      .split('\n')
      .reduce(
        (children: any, textSegment: any, index: number) => [...children, index > 0 && <br key={index} />, textSegment],
        []
      ),

  renderMark: {
    [MARKS.BOLD]: (text: ReactNode) => <b>{text}</b>,
  },
  renderNode: {
    [BLOCKS.HEADING_1]: (_, children: ReactNode) => (
      <h1 className="py-2 mx-auto text-2xl md:text-3xl text-black">{children}</h1>
    ),
    [BLOCKS.HEADING_2]: (_, children: ReactNode) => (
      <h2 className="py-2 mx-auto text-2xl md:text-3xl text-black">{children}</h2>
    ),
    [BLOCKS.HEADING_4]: (_, children: ReactNode) => (
      <h4 className="py-2 mx-auto text-4xl md:text-3xl text-black">{children}</h4>
    ),
    [BLOCKS.HEADING_3]: (_, children: ReactNode) => (
      <h3 className="py-2 mx-auto text-3xl md:text-3xl text-darkText bold">{children}</h3>
    ),
    [BLOCKS.PARAGRAPH]: (_, children: ReactNode) => <p className="text-lg text-black">{children}</p>,
    [INLINES.HYPERLINK]: (node: any, children: ReactNode) => (
      <a href={node?.data?.uri} className="sky-text-daylight">
        {children}
      </a>
    ),

    //[BLOCKS.UL_LIST]: (_, children: ReactNode) => <ul className="text-lg text-black unorderedList">{children}</ul>,
  },
};

export const DocumentToReactComponents = ({ richtextEditorProps }: { richtextEditorProps?: Document }) => {
  return <>{richtextEditorProps && <>{documentToReactComponents(richtextEditorProps, renderOptions)}</>}</>;
};
