import React from 'react';// Adjust the path as needed
import { useAuth0 } from '@auth0/auth0-react';

const LoginButton = () => {
  const { loginWithRedirect , isAuthenticated} = useAuth0();

  return (
    !isAuthenticated ?
            <React.Fragment><button className='pb-0 inline-block text-white text-xl bold' onClick={() => loginWithRedirect()}>
      Log In
    </button>
    </React.Fragment>:<></>
  );
};

export default LoginButton;
