import axios, { AxiosRequestConfig } from "axios";
import { BACKEND_ENDPOINT } from "../config";
import { toast } from "react-toastify";

// const url = "https://web-proxy.dev.skydev.co.nz/dev"
// const url = "http://localhost:4000"
const url = BACKEND_ENDPOINT;
let agent = axios.create({ baseURL: url });
const headerChannelInterceptor = (channel: string) => async (config: any) => {
  config.headers["channel"] = channel;
  return config;
};
agent.interceptors.request.use(headerChannelInterceptor("WEB"));
let instance = axios.create({
  headers: {
    post: {
      // can be common or any other method
      channel: "WEB",
    },
  },
});

interface Payload {
  firstname?: string;
  lastname?: string;
  company?: string;
  email?: string;
  captcha?:string;
}
async function sendEmail(payload: Payload): Promise<any> {
  try {
    const response = await agent.post<any>("/advestisingSendEmail", payload);
    if(response.status===200 && response?.data?.msg!=="Error: CAPTCHA verification failed. Please try again."){
      toast.success("Success: Your request was completed successfully!", {
        className: "toast-success",
      });
    }
    return response?.data;
  } catch (err) {
    toast.error("Failed to submit the form. Please try again.");
    return [];
  }
}

export { sendEmail };
