import { gql } from "@apollo/client";

export const linkFeilds = gql`
  fragment linkFeilds on AdvertisingLinks {
linkUrl
linkText
internalName
linkDocument{
  url
  text
}
}`;