import React, { ReactNode } from 'react';
import { Document, BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { Options, documentToReactComponents } from '@contentful/rich-text-react-renderer';
//import './style.css';
import { HORIZONTAL_VIDEO_PLAYER_URL } from '../config';

interface TermsAndConditionContentProps {
  content: TermsAndConditionContentType[];
}

export type TermsAndConditionContentType = {
  heading: string;
  internalName: string;
  isExpandable: boolean;
  slug: string;
  subHeading?: string;
  content?: Document;
  headingTextPosition?:string;
  headingBackgroundGradientColor?:string;
  backgroundImage?:any;
  videoUrl?:string;
  logoImage?:any;
};

export const FreeFormContent = ({ content }: TermsAndConditionContentProps) => {
  const tableProps = {
    style: { /*borderWidth: '1px' fontWeight:"bold"*/},
    className: 'border-collapse border-black text-left',
  };

  const tableBodyStyle = {
    style: { ...tableProps.style },
    className: `${tableProps.className} py-2 w-1/2 md:w-3/5 lg:w-3/5`,
  };
  const tableHeadStyle = {
    style: { ...tableProps.style },
    className: `${tableProps.className} py-2 w-1/2 md:w-3/5 lg:w-3/5 bold`,
  };
  const renderOptions: Options = {
    
    renderText: (text: string) =>
      text
        .split('\n')
        .reduce(
          (children: any, textSegment: any, index: number) => [
            ...children,
            index > 0 && <br key={index} />,
            textSegment,
          ],
          []
        ),

    renderMark: {
      [MARKS.BOLD]: (text: ReactNode) => <b>{text}</b>,
    },
    renderNode: {
      [BLOCKS.HEADING_3]: (_, children: ReactNode) => (
        <h3 className="py-2 mx-auto text-2xl md:text-3xl text-black">{children}</h3>
      ),
      [BLOCKS.HEADING_4]: (_, children: ReactNode) => (
        <h4 className="pb-4 mx-auto text-2xl md:text-4xl text-black">{children}</h4>
      ),
      [BLOCKS.PARAGRAPH]: (_, children: ReactNode) => <p className="text-lg text-black">{children}</p>,
      [INLINES.HYPERLINK]: (node: any, children: ReactNode) => (
        <a href={node?.data?.uri} className="sky-text-daylight">
          {children}
        </a>
      ),
      [BLOCKS.OL_LIST]: (_, children: ReactNode) => 
          <div className="px-8 pb-8 lg:px-0">
            <ol className="text-4xl text-black orderedList list-number text-left">{children}</ol>
          </div>,

        /* : content[0]?.slug === 'online-and-mobile-terms' ? (
          <ol className="text-lg text-black online-mobile-terms-orderedList">{children}</ol>
        )*/ 
      [BLOCKS.UL_LIST]: (_, children: ReactNode) => <ul className="text-lg text-black unorderedList mb-8">{children}</ul>,
      [BLOCKS.LIST_ITEM]: (_, children: ReactNode) => <li className='ml-7 mt-2'>{children}</li>,
      [BLOCKS.TABLE]: (_, children: ReactNode) => (
        <table {...tableBodyStyle} className='inline ml-12'>
          <tbody>{children}</tbody>
        </table>
      ),
      [BLOCKS.TABLE_HEADER_CELL]: (_, children: ReactNode) => <th {...tableHeadStyle}>{children}</th>,
      [BLOCKS.TABLE_ROW]: (_, children: ReactNode) => <tr {...tableProps}>{children}</tr>,
      [BLOCKS.TABLE_CELL]: (_, children: ReactNode) => <td {...tableProps}>{children}</td>,
      [BLOCKS.EMBEDDED_ENTRY]: (node: any) => <DisplayContents content={node?.data?.target?.fields} />
      

    },
  };

  const Title = ({ text }: { text?: string }) =>
    text ? (
      
      <p className="text-4xl" dangerouslySetInnerHTML={{__html:(text)}}/>
    ) : (
      <></>
    );

  const SubTitle = ({ text }: { text?: string }) =>
    text ? <div className="text-lg text-black text-center font-semibold">{text}</div> : <></>;

  const TermsContent = ({ terms }: { terms?: Document }) =>
    terms ? <>{documentToReactComponents(terms, renderOptions)}</> : <></>;
  const DisplayContents = ({
    content: { heading, subHeading, content, isExpandable, backgroundImage, videoUrl, logoImage, headingTextPosition , headingBackgroundGradientColor},
  }: {
    content: TermsAndConditionContentType;
  }) => {
    
    const isEmbeddedContent = content?.content?.some((c: any) => c?.nodeType === BLOCKS.EMBEDDED_ENTRY);
    return isExpandable ? (
      <div className="md:w-11/12 md:container mx-auto">
       
          <div className="px-12 py-4 sky-bg-gray-lighter">
            <TermsContent terms={content} />
          </div>
      </div>
    ) : (
      <>
        {(headingTextPosition && headingTextPosition ==="Center" )&& (headingBackgroundGradientColor && headingBackgroundGradientColor==="Blue") && <div className='px-4 py-14 sky-blue-gradient text-center text-white'> 
          <Title text={heading} /></div>}
          
        {(headingTextPosition && headingTextPosition ==="Center" )&& (headingBackgroundGradientColor && headingBackgroundGradientColor!=="Blue") && <div className='px-2 -pb-3 lg:px-0 container mx-auto text-center bold'> 
        <Title text={heading} /></div>}
        <SubTitle text={subHeading} />
        {(backgroundImage?.url || logoImage?.url|| videoUrl) &&
       <div className=" pb-8 px-2 lg:px-0  pt-6 container mx-auto  text-left" style={{"width":"90%"}}>
        {backgroundImage  && <img src={backgroundImage?.url} style={{width :"890px"}} alt=''/>}
        {logoImage  && <img src={logoImage?.url} alt=''/>}
          {videoUrl && <iframe
                      title={videoUrl}
                      //src ={'https://players.brightcove.net/6122285394001/experience_63211edca1522b0025cf4d1d/index.html?videoId=6331602517112'}
                      src={HORIZONTAL_VIDEO_PLAYER_URL + videoUrl}
                      allow="encrypted-media"
                      className="container top-0 left-0 right-0 bottom-0 w-full h-full"></iframe>}
                      </div>}
                      {headingTextPosition && headingTextPosition ==="Left" && <div className='px-8 md:px-0 md:mx-18 container mx-auto text-left bold'> <Title text={heading} /></div>}
        {heading && !isEmbeddedContent ? (
          <div className="px-2 md:px-0 pb-6 md:mx-18 container mx-auto text-left">
            <TermsContent terms={content} />
          </div>
        ) : (
          
          <TermsContent terms={content} />
        )}
      </>
    );
  };
  return (
    <div className="flex flex-col mx-auto justify-start gap-4">
      {(content ?? [])?.map((c, index) => (
        <DisplayContents key={`terms-${index}`} content={c} />
      ))}
    </div>
  );
};
