import React from 'react';

// Define the clickFunction type
type ClickFunction = (event: React.MouseEvent<HTMLButtonElement>) => void;

interface ButtonProps {
  text: string;
  onClickButton?: ClickFunction;
}

export const Button: React.FC<ButtonProps> = ({ text, onClickButton }) => {
  return (
    <button onClick={onClickButton} className="submit">
      {text}
    </button>
  );
};



