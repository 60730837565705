import { gql } from "@apollo/client";

export const popuCardFeilds = gql`
  fragment popuCardFeilds on AdvertisingPopupCardSection {
internalName
popupCardDescription
 popupCardImageURL
 popupCardTitle
 popupCardSubTitle
 popupCardImageLinkURL
 linkText
 linkUrl
}`;