import React, { useState } from 'react';
import cx from 'classnames';
import { Link } from '../types';
import { ArrowDown, ArrowUp } from './icon';

interface AccordionProps {
  items: Link[];
  name?: string;
}

const Accordion = ({ name,items}: AccordionProps) => {
  const [open, setOpenState] = useState(false);
  const toggleAccordian = () => setOpenState(prevState => !prevState);
  return (
    <div>
      <div className='flex flex-row items-start'
        onClick={toggleAccordian}>
        <div className="w-9/10 sky-h6-bold md:sky-h4-bold" style={{textAlign:"start",padding:"0 0 1rem 0"}}>{name}</div>
        {open ? <button className="sky-h3 md:sky-h4"><ArrowUp/></button> :
        <button className="sky-h3 md:sky-h4"><ArrowDown/></button>}

      </div>
      <hr/>
      <div className={cx('transition-all', { 'h-auto overflow-visible': open, 'h-0 overflow-hidden': !open })}>
      <ul className=" mt-4 list-none flex flex-1 flex-col align-baseline flex-no-wrap pb-8 text-white">
                {items?.map((link:any) => {
                  return (
                  <li key={link.publicName}   >
                      <a href={link.itemUrl}>{link.publicName}</a>
                  </li>
                  )
                })}
              </ul>  
      </div>
    </div>
  );
};

export { Accordion };
