

import { gql } from '@apollo/client';
import { freeFormContent } from '../fragments/freeForm';
import { MenuFields } from '../fragments/menuSitemap';
import { SubMenuFields } from '../fragments/subMenuQuery';
import { BannerContentFields } from '../fragments/bannerQuery';
import { popuCardFeilds } from '../fragments/popupCardQuery';
import { linkFeilds } from '../fragments/linksQuery';
import { resourceFields } from '../fragments/resourceQuery';
 
export const getComposeAdvertisingPageQueryCollection = gql`
${freeFormContent}
${MenuFields}
${SubMenuFields}
${BannerContentFields}
${popuCardFeilds}
${linkFeilds}
${resourceFields}
  query GetComposeAdvertisingPage($usePreview: Boolean, $slug: String){
    ComposeAdvertisingPage(usePreview: $usePreview,slug:$slug ) {
      internalName
      pageTitle
     heading
    seo{
      seoTitle
      hidePageFromSE
      description
      excludeLinks
      internalName
    }
     subMenu{
      ...SubMenuFields
     }
   content{
     content{
      resourceSet{
        ...resourceFields
      }
      menu{
       ...MenuFields
      }
      freeFormContent{
       ...freeFormContent
    }
      bannerContent{
       ...BannerContentFields
       }
       componentCardSectionTitle
       componentCardDisplayTile
       componentCards{
         internalName
         contentType
         links{
          ...linkFeilds
        }
        cardImageUrl{
          description
          file
          title
        }
        cardImagePosition
        cardTitlePosition
        imageLinkFile
        cardImageLinkUrl
         cardDescription
         linkUrl
         linkText
         isSlider
         iframeSrc
         cardTitle
         cardSubTitle
         componentCardCollection{
          componentCards
          {
            cardDescriptionTextAlignment
            linkUrl
            linkText
            cardDescription
            cardTitlePosition
            imageLinkFile
            cardImagePosition
             linkDocument{
                url
                text
              }
            links{
              ...linkFeilds
            }
            cardImageUrl{
              description
              file
              title
            }
            cardTitle
            cardSubTitle
          }
        }
         popupcards{
          ...popuCardFeilds
         }
       }
     }
   }
     }
   }`;
