import cx from "classnames";
import { animated } from "react-spring";
import { caret } from "../icon";
import { Link } from "../../types";
import { useAuth0 } from "@auth0/auth0-react";
interface MenuProps {
  link: Link[];
  //onClick: (link: Link) => void;
}

const Menu = ({ link }: MenuProps) => {
  let currentUrl = window.location.href;
  return (
    <div className="p-8 pt-2">
      <div className="border-white border-opacity-50">
        {/* header */}
        {link?.map((link) => {
          if (link?.publicName) {
            return (
              <button
                key={link?.publicName}
                className={cx(
                  "flex items-center justify-between  w-full  bold text-white sky-h4 border-b border-gray-dark border-opacity-50 py-4 hover:font-bold hover:text-blue-light",
                  {
                    "underline font-bold":
                      link.link && currentUrl.includes(link.link!),
                  }
                )}
              >
                <a href={link?.itemUrl}>
                  <div className="text-left h-8 leading-8">
                    {link?.publicName}
                  </div>
                  {link.children && (
                    <div className="w-8 h-8 transform -rotate-90">{caret}</div>
                  )}
                </a>
              </button>
            );
          } else {
            return null;
          }
        })}
      </div>
    </div>
  );
};

interface SideNavProps {
  link: Link[];
  close: () => void;
}
const SideNav = ({ link, close }: SideNavProps) => {
  const { user, isAuthenticated } = useAuth0();
  return (
    <>
      <div>
        <animated.div className="inset-0" style={{overflowY:"auto"}}>
          <Menu link={link} />
        </animated.div>
        {/* ))} */}
      </div>
    </>
  );
};

export { SideNav };
