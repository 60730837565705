import { ScrollToTop } from './ScrollToTop';
import App from './App';
import { AppState, Auth0Provider, User } from '@auth0/auth0-react';
import config  from './auth0.config';
import history from "./utils/history";
//import { To } from 'history';
export function Root() {
 
function onRedirectCallback(appState?: AppState | undefined, user?: User | undefined): void {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
}

  return (
<>
<Auth0Provider
  domain={config.domain}
  clientId={config.clientId}
  authorizationParams={{
    redirect_uri: window.location.origin
   }}
   onRedirectCallback = {onRedirectCallback}


  >
    <ScrollToTop />
<App />
</Auth0Provider>
</>
  );
}