
interface Props{
    banner?:any
}

export const HeroBannerComponent = ({banner}: Props) => {
    return (
      <><div className="bg-center bg-cover flex justify-center items-center"
          style={{
            backgroundImage: `url(${banner?.bannerContent?.bannerImage?.file})`,
            height: '580px',

          }}>
            <div className="transform-center">
                <h2 className="text-white text-5xl pb-4">{banner?.bannerContent?.bannerTitle}</h2>
                <h1 className="text-white text-3xl pb-8 leading-8">{banner?.bannerContent?.bannerDescription}</h1>
                {banner?.bannerContent?.linkText && <a href={banner?.bannerContent?.linkUrl}>
                  <button className="sky-button sky-button--dark sky-bg-midnight">
                    {banner?.bannerContent?.linkText}
                  </button>
                </a>}
            </div>
        </div>
        </>
    )
}