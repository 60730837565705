import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { getFooterItemCollectionQuery } from '../../graphQL/queries/getFooterMenuCollection';
import { Accordion } from '../Accordion';
import { SocialButtons } from '../social';
import { useAuth0 } from '@auth0/auth0-react';
export const Footer = () => {
  const footerGradient = {
      background: 'linear-gradient(to right, #00013A, #1C9AD6)',
    }; 
    const { isAuthenticated } = useAuth0();
    const [footerData,setFooterData] =  useState([]);
    const [footerNav,setFooterNav] =  useState([]);
    const [footerCopy,setFooterCopy] =  useState("");
    const { data } = useQuery( 
      getFooterItemCollectionQuery,
      {fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        const results = data?.MenuListHeader[0]?.menuItems;
      const filteredItems = results.filter((item:  any) => item?.publicName !== "Competitive Spend");
      if(isAuthenticated){setFooterData(results);}else{setFooterData(filteredItems);}
      setFooterNav(data?.MenuListHeader[0]?.menuSections);
       setFooterCopy(data?.MenuListHeader[0]?.footerCopyRight);
     },
    onError: (error) => console.log(error), }
    );
    

  useEffect(() => {
        // https://shielded.co.nz/ 
        let shielded = (window as any).ds07o6pcmkorn;
        if (shielded) {
          let frameName = new shielded({
            openElementId: '#shielded-logo',
            modalID: "modal",
          });
          frameName.init();
        }
      }, []);

      
 return(
    <footer className="text-white footer-container sky-bg-midnight p-10">
      <div className="container text-start w-full flex flex-row justify-between">
              <div>
                <p className=" hidden md:visible md:sky-sub text-left mb-4 font-bold">{data?.MenuListHeader[0]?.menuSections[0].sectionName}</p>
                    <ul className="hidden md:visible flex-1 flex-col align-baseline flex-no-wrap pb-8 text-white list-none">
                      {footerData?.map((link:any) => {
                        return (
                        <li key={link.publicName}   >
                            <a href={link.itemUrl}>{link.publicName}</a>
                        </li>
                        )
                      })}
                    </ul> 
                </div>
                <div className="visible xs:hidden relative">
                <SocialButtons/>
                </div>

        </div>

              <div className="visible md:hidden">
              <Accordion  name={data?.MenuListHeader[0]?.menuSections[0].sectionName} items={footerData} />
              </div>
              <hr className='mt-8 md:mt-0'/>
              <div className=" -ml-8 md:sky-copyright flex flex-col sm:flex-row w-full mt-5 mb-5 md:ml-2">

              <div className="whitespace-no-wrap pr-14 text-white text-left copy-right">
               {footerCopy}
              </div>
                <div>
                  <ul className="flex list-none flex-row justify-start md:justify-centerterms-cond flex-no-wrap md:pl-14 text-white">
                {footerNav?.map((link:any) => {
                  return (
                    <li key={link.publicName} className="md:sky-sub font-book ml-4">
                      <a href={link.itemUrl}>{link.publicName}</a>
                    </li>
                  );
                })}
              </ul>
                </div>
            </div>
            <div className='float-middle p-75'>
              <img alt="shielded" id="shielded-logo" height="60" width="60" style={{cursor: "pointer", margin: "0px auto", display: "inherit",  opacity:1}} src="https://shielded.co.nz/img/custom-logo.png"/>
            </div>
    </footer>
  );
};