import React from 'react';

export const SiteMap = ({ content }:any) => {
  return (
    <div>
      <h2>{content.internalName}</h2>
      <ul className="list-disc text-lg px-24 py-12 sky-h7-reg text-left text-blue-pure">
        {content.menuSections.map((section:any, index:number) => (
          <li className='py-1' key={index}>
            <h3>{section.slug ? <a href={section.slug}>{section.publicName || section.sectionName}</a>:<a href={section.itemUrl}>{section.publicName || section.sectionName}</a>}</h3>
            <ul className="list-circle text-lg px-8 md:px-8 sky-h7-reg  text-blue-pure">
              {section.sectionLinks && section.sectionLinks.map((link:any, idx:number) => (
                <li className='' key={idx}>
                  <a href={link.itemUrl}>{link.publicName}</a>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>


  );
};
   