import { gql } from '@apollo/client';

export const SubMenuFields = gql`
  fragment SubMenuFields on AdMenuList {
    internalName
      menuSections{
      itemUrl
        sectionName
        slug
        sectionLinks{
          itemUrl
          itemIcon
          publicName
          internalName
        }
        publicName
      }
  }
`;
