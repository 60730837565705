import { gql } from "@apollo/client";

export const BannerContentFields = gql`
  fragment BannerContentFields on AdvertisingHeroBannerContents {
     bannerTitle
        linkText
        linkUrl
        bannerDescription
        bannerImage{
          title
          description
          file
          
        }
  }
`;
