import React, { useState } from 'react';
import { ArrowLeft, ArrowLeftWhite, ArrowRight, ArrowRightWhite, closex } from './icon';

const ITEMS_PER_PAGE = 12;

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  currentImage: {
    url: string;
    name: string;
    prev: boolean;
    next: boolean;
    index: number; // Current image index
  };
  onNext: () => void;
  onPrev: () => void;
  totalImages: number; // Total number of images
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, currentImage, onNext, onPrev, totalImages }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button onClick={onPrev} disabled={!currentImage.prev}>
          <ArrowLeftWhite />
        </button>
        
        <img 
          src={`https:${currentImage.url}`} 
          alt={currentImage.name} 
          style={{ maxWidth: '100%', maxHeight: '100%' }} 
        />
        <button onClick={onNext} disabled={!currentImage.next}>
          <ArrowRightWhite />
        </button>
        <button className="w-16 h-16 text-white my-2 close-button" onClick={onClose}>
          {closex}
        </button>
        <div className="image-info">
          <span className='text-whiteText'>
            Photo {currentImage.index + 1} of {totalImages}
          </span>
        </div>
      </div>
    </div>
  );
};

export const Pagination: React.FC<{ cards: any }> = (cards) => {
  const popupCards = Array.isArray(cards?.cards) ? cards.cards : [];
  const [currentPage, setCurrentPage] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const totalImages = popupCards.length; // Total number of images
  const totalPages = Math.ceil(totalImages / ITEMS_PER_PAGE);
  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const currentItems = popupCards.slice(indexOfFirstItem, indexOfLastItem);

  const handleImageClick = (index: number) => {
    setCurrentImageIndex(index); // Use the index of the full image array
    setModalOpen(true);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleNextImage = () => {
    if (currentImageIndex < totalImages - 1) {
      setCurrentImageIndex((prev) => prev + 1);
    }
  };

  const handlePrevImage = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex((prev) => prev - 1);
    }
  };

  return (
    <div>
      <div className="item-list grid grid-cols-1 sm:grid-cols-3 w-full gap-0 sm:gap-12 mx-auto px-20 sm:px-60">
        {currentItems.map((item: any, index: number) => (
          <div key={item?.internalName} onClick={() => handleImageClick(index + indexOfFirstItem)}>
            <img 
              className='-mb-40 w-full h-full block'
              src={`https:${item?.popupCardImageURL}`} 
              alt={item?.internalName} 
              style={{ width: "282px", height: "220pxpx", margin: "10px" }} 
            />
          </div>
        ))}
      </div>

      <div className="pagination flex justify-between mt-12 mx-20 sm:mx-60">
        <button className='flex'
          onClick={handlePrevPage} 
          disabled={currentPage === 1}
          style={{ margin: '5px', cursor: currentPage === 1 ? 'not-allowed' : 'pointer' }}
        >
          <ArrowLeft /> <span>Previous Page</span>
        </button>
        <div className='flex'>
          {Array.from({ length: totalPages }, (_, i) => (
            <button 
              key={i + 1} 
              onClick={() => setCurrentPage(i + 1)}  className='mx-1'
              disabled={currentPage === i + 1}
              style={{ 
                textDecoration: currentPage === i + 1 ? 'underline' : 'none', 
                cursor: currentPage === i + 1 ? 'not-allowed' : 'pointer' 
              }}
            >
              {i + 1}.
            </button>
          ))}
        </div>
        <button className='flex'
          onClick={handleNextPage} 
          disabled={currentPage === totalPages}
          style={{ margin: '5px', cursor: currentPage === totalPages ? 'not-allowed' : 'pointer' }}
        >
         <span> Next Page </span><ArrowRight />
        </button>
      </div>

      <Modal 
        isOpen={modalOpen} 
        onClose={handleCloseModal} 
        currentImage={{
          url: popupCards[currentImageIndex]?.popupCardImageURL,
          name: popupCards[currentImageIndex]?.internalName,
          prev: currentImageIndex > 0,
          next: currentImageIndex < totalImages - 1,
          index: currentImageIndex // Current index in total
        }} 
        totalImages={totalImages} // Total number of images
        onNext={handleNextImage} 
        onPrev={handlePrevImage} 
      />
    </div>
  );
};
